import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout"
import {getCurrentLocale, getTranslatedFields, getTranslatedValue} from "../helpers/fields";
const slugs = require("../helpers/slugs");


class Map extends React.Component {
    render() {
        const locale = getCurrentLocale(this);
        const translations = this.props.pageContext.translations;
        const translatedFields = getTranslatedFields(this, translations);
        const { map_entrance, map_tickets, map_wardrobe,
            map_giftsshop, map_lift, map_audiotours, map_wc,
            map_wc_disabled, map_emergencyexit, map_floor2_text,
            map_floor2_subtext, map_floor1_text, map_floor1_subtext,
            map_floor0_text, map_floorminusone_text, information,
            map_floor2_text_right, map_floor1_text_right, map_floor0_text_right,
            map_floorminusone_text_right} = translatedFields;
        const translated_title = getTranslatedValue(this, translations.map);

        return (
            <Layout page={this} title={translated_title} className='kaart' lang={locale} includeMenu={true} translations={translations}>
                <div className="wrapper">
                    <div className="kaart-wrapper">
                        <div class="indicator"></div>
                        <img src="/img/kaarten/plattegrond_MH.svg" />
                        <table className="legenda">
                            <tbody>
                                <tr>
                                    <td className="verdieping">2</td>
                                    <td className="mauritshuis">
                                        <Link to={slugs.getFloorSlug(2, locale)}>{ map_floor2_text }</Link><br />
                                        <span>{ map_floor2_subtext }</span>
                                    </td>
                                    <td className="spacer"></td>
                                    <td className="rdsv">{map_floor2_text_right}</td>
                                    <td className="verdieping">2</td>
                                </tr>
                                <tr>
                                    <td className="verdieping">1</td>
                                    <td className="mauritshuis">
                                        <Link to={slugs.getFloorSlug(1, locale)}>{ map_floor1_text }</Link><br />
                                        <span>{map_floor1_subtext}</span>
                                    </td>
                                    <td></td>
                                    <td className="rdsv">
                                        {map_floor1_text_right}
                                    </td>
                                    <td className="verdieping">1</td>
                                </tr>
                                <tr>
                                    <td className="verdieping">0</td>
                                    <td className="mauritshuis">{map_floor0_text}</td>
                                    <td></td>
                                    <td className="rdsv">{map_floor0_text_right}</td>
                                    <td className="verdieping">0</td>
                                </tr>
                                <tr>
                                    <td className="verdieping">-1</td>
                                    <td className="mauritshuis">{map_floorminusone_text}</td>
                                    <td></td>
                                    <td className="rdsv">{map_floorminusone_text_right}</td>
                                    <td className="verdieping">-1</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colSpan="4">
                                        <div className="foyer-list">
                                            <h4>Legenda</h4>
                                            <ul>
                                                <li className="entree">{map_entrance}</li>
                                                <li className="tickets">{map_tickets}</li>
                                                <li className="garderobe">{map_wardrobe}</li>
                                                <li className="informatie">{information}</li>
                                                <li className="winkel">{map_giftsshop}</li>
                                            </ul>
                                            <ul>
                                                <li className="lift">{map_lift}</li>
                                                <li className="audio">{map_audiotours}</li>
                                                <li className="wc">{map_wc}</li>
                                                <li className="handicap">{map_wc_disabled}</li>
                                                <li className="nood">{map_emergencyexit}</li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
            </Layout>
        );
    }
}

export default Map;